<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />
    <div style="max-width: 600px;">
      <strong>Comentário sobre a irrigação:</strong>
      <p>{{ item.pergunta_comentario_irrigacao }}</p>
      <hr>
      <strong>Comentário sobre a limpeza da oficina, almoxarifado e pátio:</strong>
      <p>{{ item.pergunta_comentario_limpeza }}</p>
      <hr>
      <strong>Comentário sobre a manutenção e cuidados com a frota:</strong>
      <p>{{ item.pergunta_comentario_manutencao_frota }}</p>
      <hr>
      <strong>Comentário sobre a checagem do estoque:</strong>
      <p>{{ item.pergunta_comentario_checagem_estoque }}</p>
      <hr>
      <strong>Comentário sobre os lançamentos no modulo agrícola:</strong>
      <p>{{ item.pergunta_comentario_lancamentos_agricola }}</p>
      <hr>
      <strong>Comentários gerais sobre a produção:</strong>
      <p>{{ item.avaliacao_comentario }}</p>
      <hr>
    </div>
    <v-data-table
        class="w-auto"
        item-key="seqipo"
        dense
        :headers="headersItens"
        :items="item.respostas_talhoes"
        :items-per-page="10"
        :mobile-breakpoint="0"
    >

      <template v-slot:progress>
        <v-progress-linear
            absolute
            indeterminate
            color="green"
        />
      </template>

      <template v-slot:[`item.total_aplicado`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.total_aplicado, 2) }}</span>
      </template>

      <template v-slot:[`item.dosagem`]="{ item }">
        <span class="num_margin">{{ $stringFormat.formatNumber(item.dosagem, 2) }}</span>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
              <span>
                <v-icon
                    small
                    @click="verFotos(item)"
                    :title="item.fotos.length > 0 ? 'Visualise as Fotos' : 'Sem Fotos'"
                    :disabled="loading || item.fotos.length === 0"
                >
                  mdi-image-multiple
                </v-icon>
              </span>
      </template>

    </v-data-table>
    <VisualizarFotosDialog v-model="viewDialog" :item="itemSelecionado"></VisualizarFotosDialog>
  </v-container>
</template>

<script>

import VisualizarFotosDialog from './VisualizarFotosDialog.vue'

export default {
  components: { VisualizarFotosDialog },
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    viewDialog: false,
    itemSelecionado: null,
  }),
  computed: {
    headersItens () {
      return [
        { text: 'Talhão', value: 'talhao' },
        { text: 'Coloração OK', value: 'pergunta_coloracao' },
        { text: 'Adubação em dia', value: 'pergunta_adubacao' },
        { text: 'Adubação feita nas datas', value: 'pergunta_adubacao_datas_corretas' },
        { text: 'Existem ervas daninhas', value: 'pergunta_ervas_daninha' },
        { text: 'Tipos ervas daninhas', value: 'pergunta_tipo_ervas_daninha' },
        { text: 'Altura da poda está correta', value: 'pergunta_altura_poda' },
        { text: 'Sintomas', value: 'pergunta_sintoma' },
        { text: 'Nota de avaliação', value: 'avaliacao_nota' },
        { text: 'Ações', value: 'acoes' },
      ]
    },
  },
  methods: {
    converteUnidadeMedida (unidadeMedida, valor) {
      if (unidadeMedida === 'TN') {
        return parseFloat(valor) * 1000
      }

      return parseFloat(valor)
    },
    verFotos (item) {
      this.viewDialog = true
      this.itemSelecionado = item
    },
  },
}
</script>
