import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('custom-snackbar',{ref:"snackbar"}),_c('div',{staticStyle:{"max-width":"600px"}},[_c('strong',[_vm._v("Comentário sobre a irrigação:")]),_c('p',[_vm._v(_vm._s(_vm.item.pergunta_comentario_irrigacao))]),_c('hr'),_c('strong',[_vm._v("Comentário sobre a limpeza da oficina, almoxarifado e pátio:")]),_c('p',[_vm._v(_vm._s(_vm.item.pergunta_comentario_limpeza))]),_c('hr'),_c('strong',[_vm._v("Comentário sobre a manutenção e cuidados com a frota:")]),_c('p',[_vm._v(_vm._s(_vm.item.pergunta_comentario_manutencao_frota))]),_c('hr'),_c('strong',[_vm._v("Comentário sobre a checagem do estoque:")]),_c('p',[_vm._v(_vm._s(_vm.item.pergunta_comentario_checagem_estoque))]),_c('hr'),_c('strong',[_vm._v("Comentário sobre os lançamentos no modulo agrícola:")]),_c('p',[_vm._v(_vm._s(_vm.item.pergunta_comentario_lancamentos_agricola))]),_c('hr'),_c('strong',[_vm._v("Comentários gerais sobre a produção:")]),_c('p',[_vm._v(_vm._s(_vm.item.avaliacao_comentario))]),_c('hr')]),_c(VDataTable,{staticClass:"w-auto",attrs:{"item-key":"seqipo","dense":"","headers":_vm.headersItens,"items":_vm.item.respostas_talhoes,"items-per-page":10,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.total_aplicado",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.total_aplicado, 2)))])]}},{key:"item.dosagem",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.dosagem, 2)))])]}},{key:"item.acoes",fn:function(ref){
var item = ref.item;
return [_c('span',[_c(VIcon,{attrs:{"small":"","title":item.fotos.length > 0 ? 'Visualise as Fotos' : 'Sem Fotos',"disabled":_vm.loading || item.fotos.length === 0},on:{"click":function($event){return _vm.verFotos(item)}}},[_vm._v(" mdi-image-multiple ")])],1)]}}],null,true)}),_c('VisualizarFotosDialog',{attrs:{"item":_vm.itemSelecionado},model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }