<template>
  <div>
    <custom-snackbar ref="snackbar" />
    <ActionsComponent v-model="headers" :filtro="filtros" :headers-options="headersOptions" local-storage-save-name="relatorio-visitas"/>
    <FilterComponent v-model="filtros" />
    <ListComponents :filtros="filtros" :headers="headers"/>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ActionsComponent from './components/ActionsComponent'
import FilterComponent from './components/FilterComponent'
import ListComponents from './components/ListComponents'

export default {
  components: { ActionsComponent, FilterComponent, ListComponents },
  data: (instance) => {
    return {
      filtros: {
        dataInicio: instance.filtroValue(instance.$route.query.dataInicio, instance.$date.getDatePreviousWeekIso(), 'date'),
        dataFim: instance.filtroValue(instance.$route.query.dataFim, instance.$date.getDateNowIso(), 'date'),
        empresaId: instance.filtroValue(instance.$route.query.empresaId, '', 'int'),
        producaoId: instance.filtroValue(instance.$route.query.producaoId, '', 'int'),
        talhaoId: instance.filtroValue(instance.$route.query.talhaoId, '', 'int'),
      },
      headers: [],
      headersOptions: [
        { value: 'data-table-expand', align: 'right p-0', class: 'sticky-header custom-table--header', total: 'title' },
        { text: 'Empresa', value: 'empresa', class: 'sticky-header custom-table--header', obrigatorio: true },
        { text: 'Producao', value: 'producao', class: 'sticky-header custom-table--header', obrigatorio: true },

        { text: 'Usuario', value: 'usuario', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Data Visita', value: 'visitado_em', class: 'sticky-header custom-table--header', ativado: true },

        { text: 'Apresentação', value: 'pergunta_apresentacao_palete', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Perdas por regulagem', value: 'pergunta_perdas_regulagem', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Problema ou Consideração Irrigação', value: 'pergunta_irrigacao_producao', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Nota Para Limpeza', value: 'pergunta_nota_limpeza', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Manutenção e Cuidados Com a Frota', value: 'pergunta_manutencao_frota', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Checagem do Estoque OK', value: 'pergunta_checagem_estoque', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Lançamentos no Agricola', value: 'pergunta_lancamentos_agricola', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Nota de Avaliação', value: 'avaliacao_nota', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Ações', value: 'acoes', class: 'sticky-header custom-table--header', obrigatorio: true },
      ],
    }
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Relatório de Visitas nas Fazendas' },
    ])
  },
  methods: {
    filtroValue (value, valorPadrao, type) {
      if (value === undefined || value === null || value === '') {
        return valorPadrao
      }

      if (type === 'int') {
        return parseInt(value)
      }

      if (type === 'float') {
        return parseFloat(value)
      }

      if (type === 'array') {
        return [].concat(value)
      }

      return value
    },
  },
}
</script>
